import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { getBorderColor, Target } from "../../../utils"

type Props = {
  items: {
    image: IGatsbyImageData
    name: string
    position?: string
    duties?: string
    qualifications?: string
    comment: string
  }[]
  target: Target
}

const Staff: React.VFC<Props> = ({ items, target }) => {
  return (
    <div className="grid-cols-2 gap-8 mt-12 space-y-8 md:grid md:space-y-0 md:mt-16">
      {items.map(item => {
        return (
          <div
            className={`grid grid-cols-2 gap-5 p-4 border-4 border-opacity-20 md:p-8 ${getBorderColor(
              target
            )} rounded-3xl`}
            key={item.name}
          >
            <div>
              {item.image ? (
                <GatsbyImage
                  image={item.image}
                  alt="staff"
                  className="rounded-3xl max-h-52"
                />
              ) : (
                <StaticImage
                  src="../../../images/shared/human.png"
                  alt="human"
                  className="rounded-3xl"
                />
              )}
              <div className="mt-5 text-center">
                <div className="text-lg font-kiwiMaru">{item.name}</div>
                <div>{item.position}</div>
                <div>{item.duties}</div>
                <div>{item.qualifications}</div>
              </div>
            </div>
            <div>{item.comment}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Staff
